"use client";
import React, { useState, useEffect } from "react";
import IconComponent from "./IconComponent";

type CountdownMonthlyTimerProps = {
  website: string;
};

export const CountdownMonthlyTimer: React.FC<
  CountdownMonthlyTimerProps
> = ({}) => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date();
      const targetDate = getNextMonthlyReset();

      const timeLeft = targetDate.getTime() - now.getTime();

      if (timeLeft <= 0) {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return false;
      } else {
        setCountdown({
          days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
        });
        return true;
      }
    };

    const interval = setInterval(() => {
      const isCountdownActive = calculateCountdown();
      if (!isCountdownActive) {
        clearInterval(interval);
        setTimeout(() => {
          calculateCountdown();
          setInterval(calculateCountdown, 1000);
        }, 1000);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center text-white font-popping">
      <div className="font-bold text-center mb-4 flex items-center justify-center">
        <span className="text-red-700 animate-pulse">NEW</span>
        <span className="ml-2">LEADERBOARD STARTS IN</span>
      </div>

      <div className="flex items-center w-full justify-center">
        <div className="border-t border-white flex-grow max-w-[16rem]"></div>
        <div className="flex space-x-4 mx-4">
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.days}</span>
            <span className="text-sm text-red-700">DAYS</span>
          </div>
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.hours}</span>
            <span className="text-sm text-red-700">HRS</span>
          </div>
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.minutes}</span>
            <span className="text-sm text-red-700">MIN</span>
          </div>
          <div className="flex flex-col items-center bg-black bg-opacity-75 p-4 rounded-full shadow-red w-20 h-20 justify-center">
            <span className="text-3xl text-white">{countdown.seconds}</span>
            <span className="text-sm text-red-700">SEC</span>
          </div>
        </div>
        <div className="border-t border-white flex-grow max-w-[16rem]"></div>
      </div>
    </div>
  );
};

const getNextMonthlyReset = () => {
  const now = new Date();
  const nextMonth = new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 1);
  nextMonth.setUTCDate(0);
  nextMonth.setUTCHours(23, 59, 59, 999);

  return nextMonth;
};
